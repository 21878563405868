import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initializeTooltips()
  }

  disconnect() {
    this.destroyTooltips()
  }

  initializeTooltips() {
    this.destroyTooltips()
    $('[data-toggle="tooltip"]', this.element).tooltip()
  }

  destroyTooltips() {
    $('[data-toggle="tooltip"]', this.element).tooltip('dispose')
  }
}