import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Initial page load
  connect() {
  }

  onMapToggle() {
    $('.map-col-container').toggleClass('d-none');
  }

  openMap() {
    $('.map-col-container').removeClass('d-none');
  }
}
