document.addEventListener("turbo:load", function () {
  function initializePasswordToggles() {
    function togglePasswordVisibility(toggleButtonId, passwordFieldId, iconId) {
      $(toggleButtonId).off('click').on('click', function(e) {
        e.preventDefault();
        let passwordField = $(passwordFieldId);
        let passwordFieldType = passwordField.attr('type');
        let passwordToggleIcon = $(iconId);
        
        if (passwordFieldType === 'password') {
          passwordField.attr('type', 'text');
          passwordToggleIcon.removeClass('fas fa-eye').addClass('fas fa-eye-slash');
        } else {
          passwordField.attr('type', 'password');
          passwordToggleIcon.removeClass('fas fa-eye-slash').addClass('fas fa-eye');
        }
      });
    }

    togglePasswordVisibility('#signup-password-toggle', '#signup-password-field', '#signup-password-toggle-icon');
    togglePasswordVisibility('#signup-password-confirmation-toggle', '#signup-password-confirmation-field', '#signup-password-confirmation-toggle-icon');
    togglePasswordVisibility('#password-toggle', '#password-field', '#password-toggle-icon');  // Keep login modal toggle
  }

  // Initialize on page load
  initializePasswordToggles();

  // Re-initialize when modals are shown
  $('.modal').on('shown.bs.modal', function () {
    initializePasswordToggles();
  });
});