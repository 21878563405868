import ModalController from "./modal_controller"

export default class extends ModalController {
  static targets = ["errorMessages"]

  connect() {
    super.connect()
  }

  clearFormAndErrors() {
    // Clear all error messages and styling
    $('.field_with_errors').removeClass('field_with_errors')
    $('.error-message').remove()
    $('.error-spacer').remove()
    
    // Clear all form inputs
    $('form input').not('[type="hidden"]').val('')
  }

  switchToSignup(event) {
    if (event) event.preventDefault()
    this.clearFormAndErrors()
    $(this.element).modal('hide')
    $('#sign-up-modal').modal('show')
  }

  switchToLogin(event) {
    if (event) event.preventDefault()
    this.clearFormAndErrors()
    $(this.element).modal('hide')
    $('#login-modal').modal('show')
  }

  showCheckEmail(event) {
    if (event) event.preventDefault()
    this.clearFormAndErrors()
    $('#sign-up-modal').modal('hide')
    $('#check-inbox-modal').modal('show')
  }

  closeAllModals(event) {
    if (event) event.preventDefault()
    $('.modal').modal('hide')
    $('.modal-backdrop').remove()
    $('body').removeClass('modal-open')
  }

  handleError(event) {
    const [data, status, xhr] = event.detail
    
    // Clear any existing error messages and spacers
    $('.field_with_errors').removeClass('field_with_errors')
    $('.error-message').remove()
    $('.error-spacer').remove()
    
    // First pass: add all error messages
    Object.entries(data.errors || {}).forEach(([field, messages]) => {
      const fieldToId = {
        password: 'signup-password-field',
        password_confirmation: 'signup-password-confirmation-field'
      }
      
      const inputField = $(`#${fieldToId[field] || `user_${field}`}`)
      
      if (inputField.length) {
        inputField.parent().addClass('field_with_errors')
        
        const capitalizedMessages = messages.map(msg => 
          msg.charAt(0).toUpperCase() + msg.slice(1)
        )
        
        let errorHTML
        if (capitalizedMessages.length > 1) {
          errorHTML = '<div class="error-message text-danger small mb-1"><ul class="mb-0 pl-3">'
          capitalizedMessages.forEach(message => {
            errorHTML += `<li>${message}</li>`
          })
          errorHTML += '</ul></div>'
        } else {
          errorHTML = `<div class="error-message text-danger small mb-1">${capitalizedMessages[0]}</div>`
        }
        
        inputField.closest('.form-group').prepend(errorHTML)
      }
    })
    
    // Second pass: handle grid alignment for error messages to keep inputs on the same row on desktop
    if (window.innerWidth >= 768) { // Bootstrap's md breakpoint
      $('.form-row').each(function() {
        const formGroups = $(this).find('.form-group')
        const maxErrorHeight = Math.max(...formGroups.map(function() {
          const errorMessage = $(this).find('.error-message')
          return errorMessage.length ? errorMessage.outerHeight() : 0
        }).get())
        
        if (maxErrorHeight > 0) {
          formGroups.each(function() {
            const hasError = $(this).find('.error-message').length > 0
            if (!hasError) {
              $(this).prepend(`<div class="error-spacer" style="visibility: hidden; height: ${maxErrorHeight}px;"></div>`)
            } else {
              $(this).find('.error-message').css('min-height', `${maxErrorHeight}px`)
            }
          })
        }
      })
    }
  }
}
