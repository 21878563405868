document.addEventListener('turbo:load', function () {
  $('#story_place_ids').chosen({
    width: '400px',
    placeholder_text_multiple: 'Choose Places',
  });
  $('#story_story_region_ids').chosen({
    width: '400px',
    placeholder_text_multiple: 'Choose Story Regions',
  });
  $('#place_groupings_story_ids').chosen({
    width: '400px',
    placeholder_text_multiple: 'Choose Place Groupings',
  });
  $('#story_category_ids').chosen({
    width: '400px',
    placeholder_text_multiple: 'Choose Story Categories',
  });
  $('.searchable_dropdown').chosen({
    width: '100%',
    placeholder_text_multiple: 'Choose Categories',
  });
});
