import { Controller } from "@hotwired/stimulus"

// NOTE: This controller is specifically designed to manage place modals within maps.
// While it currently serves its purpose, it may require adjustments if used for other modal types.
// Consider renaming this controller for clarity and creating a separate controller for different modal functionalities if necessary.
export default class extends Controller {
  initialize() {
    // Create bound handler once and store it
    this.boundHandleOutsideClick = this.handleOutsideClick.bind(this)
    this.modal = this.element
  }

  connect() {
    document.addEventListener("click", this.boundHandleOutsideClick)
    this.expandAndCenterModal()
    // if modal with body class of place-modal-body is found, scroll to it
    const placeModalBody = document.querySelector('.place-modal-body')
    if (placeModalBody) {
      placeModalBody.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  disconnect() {
    document.removeEventListener("click", this.boundHandleOutsideClick)
  }

  close(event) {
    // using javascript to close the modal with css.
    this.modal.style.display = 'none'
    this.modal._isShown = false
  }

  expandAndCenterModal() {
    // Get the parent element of the modal
    // const parentRect = this.modal.parentElement
    // make as wide as the parent element
    this.modal.style.position = 'absolute'
    // make sure it's on top
    this.modal.style.zIndex = 1000
    // add padding
    // vertically center it and horizontally center it
    this.modal.style.left = '50%'
    this.modal.style.top = '50%'
    this.modal.style.transform = 'translate(-50%, -50%)'
    this.modal.style.width = '85%'
  }

  handleOutsideClick(event) {
    const modal = this.modal;
    const rect = modal.getBoundingClientRect();

    // Check if the click event's coordinates are within the bounding box of the modal
    // Hack to check if the click event was on an input element
    // This prevents the modal from closing when clicking on an input element
    const isInput = event.srcElement.tagName === 'INPUT'

    const isInsideModal = (
      (event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom) || isInput
    );

    if (!isInsideModal && !isInput) {
      this.close()
    }
  }
}
