import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"];

  connect() {
    const navLinks = this.element.querySelectorAll('.nav_link');
    const menuDropdowns = document.getElementById('menu-dropdowns');

    navLinks.forEach(navLink => {
      navLink.addEventListener('click', function () {
        const dropdown = this.nextElementSibling;
        if (dropdown && dropdown.classList.contains('dropdown-options')) {
          const clonedDropdown = dropdown.cloneNode(true);

          // Check if the clonedDropdown ID matches the currently displayed dropdown
          if (menuDropdowns.firstChild && menuDropdowns.firstChild.id === clonedDropdown.id) {
            // If it matches, hide the menu and return
            menuDropdowns.innerHTML = '';
            // remove padding
            menuDropdowns.style.padding = '0';
            return;
          }

          clonedDropdown.classList.toggle('d-none'); // Toggle visibility before appending

          // Clear existing contents and append the cloned dropdown
          menuDropdowns.innerHTML = '';
          menuDropdowns.appendChild(clonedDropdown);
          // add padding to menuDropdowns
          menuDropdowns.style.padding = '0';

          // Move menuDropdowns to the same horizontal position as the clicked navLink only on large screens
          if (true || window.innerWidth > 768) {
            const navLinkRect = navLink.getBoundingClientRect();
            menuDropdowns.style.left = `${navLinkRect.left}px`;
          } else {
            menuDropdowns.style.left = '0';
          }
        }
      });
    });

    window.addEventListener('resize', this.handleResize.bind(this));
  }

  disconnect() {
    // close the dropdown
    const menuDropdowns = document.getElementById('menu-dropdowns');
    menuDropdowns.innerHTML = '';
    menuDropdowns.style.padding = '0';

    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    // close the dropdown
    const menuDropdowns = document.getElementById('menu-dropdowns');
    menuDropdowns.innerHTML = '';
    menuDropdowns.style.padding = '0';
  }

  toggleActive(event) {
    // Remove active class from all links
    // except the one that was clicked
    this.linkTargets.forEach(link => {
      if (link !== event.currentTarget) {
        link.classList.remove("active");
      }
    });

    // Add active class to the clicked link
    event.currentTarget.classList.toggle("active");
  }
}
