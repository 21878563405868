import EsriMap from '../../esri_map';

export function setCharacterCount(input_id, text_id, minLength, maxLength) {
  let currentCharCount = $(input_id).val().length;
  if (maxLength && minLength) {
    if (currentCharCount < minLength) {
      $(text_id).text(`${currentCharCount}/${minLength} min characters`);
    } else {
      $(text_id).text(`${currentCharCount}/${maxLength} max characters`);
    }
  } else {
    $(text_id).text(currentCharCount);
  }
}

export function updateTextCount(inputId, textId, maxLength, minLength) {
  setCharacterCount(inputId, textId, maxLength, minLength)  // On Page load
  $(inputId).on("change keyup", function() {
    setCharacterCount(inputId, textId, maxLength, minLength);
  });
}

export function toggleDisabledOnInput(inputId) {
  if ($(inputId).val()) {
    $(inputId).parent().find('.input-group-append input').prop('disabled', false);
  } else {
    $(inputId).parent().find('.input-group-append input').prop('disabled', true);
  }
}

export function removeRedirectErrors() {
  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('error_message')) {
    urlParams.delete('error_message');
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    $(".input-group").each(function() {
      $(this).removeClass('display-error');
    });
    $(".invalid-feedback").each(function() {
      $(this).removeClass('display-error');
    });
  }
}

export function instantiateMapAndRender(mapIdentifier, delay = 0) {
  const mapInstance = new EsriMap(mapIdentifier);
  const mapElement = $(`#${mapIdentifier}`);
  const placesData = mapElement.attr('data-places');
  const parsedPlacesData = JSON.parse(placesData);
  mapInstance.renderPlaces(parsedPlacesData.places, delay);
}

export function mapHasNotBeenInstantiated(mapIdentifier) {
  return $('.leaflet-pane').length === 0 && $(`#${mapIdentifier}`).length > 0;
}
