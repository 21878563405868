import { Controller } from "@hotwired/stimulus"
import { toggleDisabledOnInput, updateTextCount, instantiateMapAndRender, mapHasNotBeenInstantiated, removeRedirectErrors } from './index';

const mapIdentifier = 'desktopMap';

export default class extends Controller {
  // Initial page load
  connect() {


    document.querySelectorAll('.always-enabled').forEach(button => {
      button.disabled = false;
    });

    if (mapHasNotBeenInstantiated(mapIdentifier)) {
      instantiateMapAndRender(mapIdentifier);
    }
    this.updateNameCount();
    this.updateDescCount();
    this.checkWidthAndApplyChanges(); // Run immediately on connect
    document.addEventListener('DOMContentLoaded', this.checkWidthAndApplyChanges.bind(this));
    window.addEventListener('resize', this.checkWidthAndApplyChanges.bind(this));
    this.focusOnError();
    this.initializeExitConfirmationModal();
  }

  initializeExitConfirmationModal() {
    $(document).on('click', 'a', function (event) {
      const selectedLinkHref = $(this).attr('href');
      // Only enable this functionality on the my_story edit and new pages
      if (window.location.pathname.includes('/my_stories/edit') || window.location.pathname.includes('/my_stories/new')) {
        // If the any of the parents DONT' include .new_my_story then show the modal
        // Essentially any link clicked outside of this form will trigger the modal.
        if ($(this).parents('.my-stories-form').length === 0) {
          event.preventDefault();
          // Load the the correct modal based on the screen size.
          let modalId = '#exitModalDesktop';
          $(modalId).modal('show');
          $(modalId).on('click', '#exitModalSave', function () {
            // NOTE: Doing $('.new_my_story').trigger('submit') will not work
            // as it submits the form twice. Instead, we need to update the hidden field
            // and allow the form to submit "naturally" with `return true`. Then we can
            // know the desired action in the controller from the hidden field.
            $(modalId).find('#selected_href').val(selectedLinkHref);
            return true;
          });
          $(modalId).on('click', '#exitModalDiscard', function () {
            window.location.href = selectedLinkHref;
          });
        }
      }
    });
  }

  disconnect() {
    $(document).off('click', 'a');
    $(document).off('click', '#exitModalSave');
    $(document).off('click', '#exitModalDiscard');
  }

  goToNextStep() {
    if (this.validateForm()) {
      this.navigateToFormStep2();
    }
  }

  validateForm() {
    const newForm = $('#new_my_story_desktop');
    const invalidInputs = $(newForm).find('input:invalid, textarea:invalid');
    if (invalidInputs.length > 0) {
      // get first invalid input
      const invalidInput = invalidInputs[0];
      $(invalidInput).trigger('focus');
      // Make border red
      $(invalidInput).css('border', '1px solid red');
      return false;
    }
    return true;
  }

  disconnect() {
    document.removeEventListener('DOMContentLoaded', this.checkWidthAndApplyChanges.bind(this));
    window.removeEventListener('resize', this.checkWidthAndApplyChanges.bind(this));
  }

  submitForm() {
    removeRedirectErrors();
    const newForm = $('#new_my_story_desktop');
    const invalidInputs = $(newForm).find('input:invalid, textarea:invalid');
    if (invalidInputs.length > 0) {
      // get first invalid input
      const invalidInput = invalidInputs[0];
      // focus on the first invalid input
      $(invalidInput).trigger('focus');
    }

    return invalidInputs.length === 0;
  }

  onMapToggle() {
    $('.map-col-container').toggleClass('d-none');
  }

  openMap() {
    $('.map-col-container').removeClass('d-none');
  }

  closeMap() {
    $('.map-col-container').addClass('d-none');
  }

  updateNameCount() {
    updateTextCount('#my_story_name_desktop', '#name-max-length-desktop');
  }

  updateDescCount() {
    updateTextCount('#my_story_description_desktop', '#description-min-length-desktop', 50, 300);
  }

  placesUrlToggleDisabled() {
    toggleDisabledOnInput('#my_story_place_full_desktop');
  }

  navigateToFormStep1() {
    removeRedirectErrors();
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set('form_step', '1');
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    this.mobileFormStepDisplay();
    window.scrollTo(0, 0); // Scroll to top of page
  }

  navigateToFormStep2() {
    removeRedirectErrors();
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set('form_step', '2');
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    this.mobileFormStepDisplay();
    window.scrollTo(0, 0); // Scroll to top of page
  }

  mobileFormStepDisplay() {
    let urlParams = new URLSearchParams(window.location.search);
    $('.only-mobile').removeClass('force_hidden');
    if (!urlParams.has('form_step') || urlParams.get('form_step') === '1') {
      $('#only-mobile-title').text('Write A Story');
      $('.desktop-only').addClass('force_hidden');
      $('.form-step-1').removeClass('force_hidden');
      $('.form-step-2').addClass('force_hidden');
      this.closeMap();
    } else if (urlParams.get('form_step') === '2') {
      $('#only-mobile-title').text('Add Places');
      $('.desktop-only').addClass('force_hidden');
      $('.form-step-1').addClass('force_hidden');
      $('.form-step-2').removeClass('force_hidden');
      // if places count is greater than 0, open map
      let placeCount = $('#places-count-text').attr('data-places-count');
      placeCount = parseInt(placeCount);
      if (placeCount > 0) {
        this.openMap();
      } else {
        this.closeMap();
      }
    }
  }

  desktopFormStepDisplay() {
    $('.desktop-only').removeClass('force_hidden');
    $('.form-step-1').removeClass('force_hidden');
    $('.form-step-2').removeClass('force_hidden');
    $('.only-mobile').addClass('force_hidden');
  }

  focusOnError() {
    if ($('.display-error').length > 0) {
      $('#my_story_place_full_desktop').trigger('focus');
      // scroll to bottom of screen to see form
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  checkWidthAndApplyChanges() {
    const desktopBreakpoint = 768;
    if ($(window).width() < desktopBreakpoint) {
      this.mobileFormStepDisplay(true);
    } else {
      this.desktopFormStepDisplay();
    }
  };
}
