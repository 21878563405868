document.addEventListener("turbo:load", function () {
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  function sideBarStatus() {
    let sidebarIsOpen = !$("#wrapper").hasClass("sb-closed");
    $('.sidebar-menu').show();
    if (sidebarIsOpen) {
      setCookie('sidebar', 'closed', 1);
      $("#wrapper").addClass("sb-closed");
      $('#menu-toggle-icon').removeClass('fas fa-times');
      $('#menu-toggle-icon').addClass('fas fa-bars');
    } else {
      setCookie('sidebar', 'open', 1);
      $('#place-detail').hide();
      $('#wrapper').removeClass('sb-closed');
      $('#menu-toggle-icon').removeClass('fas fa-bars');
      $('#menu-toggle-icon').addClass('fas fa-times');
    }
  }

  $("#menu-toggle").on('click', function (e) {
    e.preventDefault();
    sideBarStatus()
  });

  $('html').on('click', function (e) {
    let didNotClickSidebar = $(e.target).closest('#sidebar-wrapper').length === 0;
    let didNotClickMap = $(e.target).closest('.leaflet-marker-icon').length === 0;
    let didNotClickMenuToggle = $(e.target).closest('#menu-toggle').length === 0;
    let sidebarIsOpen = !$("#wrapper").hasClass("sb-closed");
    let bigAdminScreen = $(window).width() >= 1000 && $("#wrapper").hasClass("admin-screen");
    if (sidebarIsOpen) {
      if (bigAdminScreen) {
        // do nothing
      } else if (didNotClickMenuToggle && didNotClickSidebar && didNotClickMap) {
        sideBarStatus()
      }
    }
  });
})
