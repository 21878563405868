import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  update_place_bookmarks(event) {
    // check if the bookmark is saved
    const permalink = event.currentTarget.dataset.permalink;
    // get story permalink from the url
    const storyPermalink = window.location.pathname
    // if the story permalink contains the permalink, then continue otherwise return
    if (!storyPermalink.includes(permalink)) {
      return;
    }

    const bookmark = this.element.querySelector('i');
    const isSaved = bookmark.classList.contains('fas');

    const bookmarks = $(".saved-place-bookmark");
    for (const bookmark of bookmarks) {
      if (isSaved) {
        bookmark.classList.remove('fas');
        bookmark.classList.add('far');
      } else {
        bookmark.classList.remove('far');
        bookmark.classList.add('fas');
      }
    }
  }
}
