import { Controller } from "@hotwired/stimulus"
import EsriMap from '../esri_map';


// Connects to data-controller="group-map"
export default class extends Controller {
  connect() {
    const mapInstance = new EsriMap('places-map-group');
    let endpoint = $('#places-map-group').data('url');
    mapInstance.getPlacesAndRender(endpoint);
  }
}
