import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  handleDropdownItemClick(event) {
    const clickedDropdownItem = $(event.target).closest('.dropdown-item');
    // If we click the item and it's already active, don't allow bootstrap to toggle
    if (clickedDropdownItem.hasClass('active')) {
      // Remove collapsable behavior
      clickedDropdownItem.attr('data-toggle', '');
    } else {
      // Add back collapsable behavior
      clickedDropdownItem.attr('data-toggle', 'collapse');
      // Remove active class from all items and add it to the clicked item
      clickedDropdownItem.closest('.dropdown-menu').find('.dropdown-item').removeClass('active');
      // Hightlight the clicked item
      clickedDropdownItem.addClass('active');
    }
  }

  handleFiltersReset(event) {
    event.preventDefault()
    const form = event.target.closest('form')
    
    // Clear checkboxes
    form.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.checked = false
    })

    // Get current URL params
    const currentParams = new URLSearchParams(window.location.search)
    currentParams.delete('category_ids[]')
    currentParams.delete('category_ids')
    
    const url = `${window.location.pathname}?${currentParams.toString()}`
    
    // Update URL and fetch in one go, just like the apply filter
    window.history.pushState({}, '', url)
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
      // Close dropdown after the content is updated
      const dropdownMenu = form.closest('.dropdown-menu')
      if (dropdownMenu) {
        $(dropdownMenu).dropdown('hide')
      }
    })
  }

  handleFilterBtnClick(event) {
    const clickedElement = $(event.target);
    let filterBtn = clickedElement;
    if (clickedElement.hasClass('fa fa-times')) {
      filterBtn = clickedElement.closest('.btn');
    }
    const filterIdentifiers = filterBtn.attr('id');
    const paramName = filterIdentifiers.split('-')[0] + '[]'; // Add [] to match how the url has the param
    const paramId = filterIdentifiers.split('-')[1];

    // Remove param from URL by param name and then id
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // Get all values for param name
    const paramValues = urlParams.getAll(paramName);
    // Remove id from param values
    const newParamValues = paramValues.filter(value => value !== paramId);
    // Update the url
    urlParams.delete(paramName);
    for (const value of newParamValues) {
      urlParams.append(paramName, value);
    }
    // Update the URL
    const newUrl = window.location.pathname + '?' + urlParams.toString();
    window.history.pushState({}, '', newUrl);
    // Reload the page
    window.location.reload();
  }

  // New method to handle form submission with Turbo Stream
  handleFilterSubmit(event) {
    event.preventDefault()
    const form = event.target
    const formData = new FormData(form)
    
    // Get current URL params to preserve non-form params
    const currentParams = new URLSearchParams(window.location.search)
    const newParams = new URLSearchParams()
    
    // Preserve non-category params
    for (const [key, value] of currentParams.entries()) {
      if (key !== 'category_ids' && key !== 'category_ids[]') {
        newParams.append(key, value)
      }
    }
    
    // Add selected category ids
    const selectedCategories = Array.from(form.querySelectorAll('input[name="category_ids[]"]:checked'))
      .map(checkbox => checkbox.value)
    
    selectedCategories.forEach(id => {
      newParams.append('category_ids[]', id)
    })
    
    const url = `${window.location.pathname}?${newParams.toString()}`
    
    window.history.pushState({}, '', url)
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
  }
}
