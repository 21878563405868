import { Controller } from "@hotwired/stimulus"
import EsriMap from '../../esri_map';

export default class extends Controller {
  connect() {
    const mapInstance = new EsriMap('single-place-map');
    const map = mapInstance.map
    const lat = this.element.dataset.lat;
    const long = this.element.dataset.lng;
    map.setView([lat, long], 13);
    L.marker([lat, long]).addTo(map)
      // .bindPopup(`<div>${lat},${long}</div>`).openPopup();
    // L.popup();
  }
}
