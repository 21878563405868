import { Controller } from "@hotwired/stimulus"
import EsriMap from '../../../esri_map';

export default class extends Controller {
  connect() {
    // MAP
    const apiKey = "AAPK2720562ea0aa448d9ffd4e56e720ce8ahvYHI83h5qAKHSAmM002AmLLd1zn_dMLh1yJNI8tE-TXpiuacoFZ7NlNo6p6TJQO"
    const esriProxy = 'https://utility.arcgis.com/usrsvcs/appservices/I9WaUoR33Blxi00f/rest/services/World/GeocodeServer';
    const lat = this.element.dataset.lat;
    const long = this.element.dataset.lng;

    const mapInstance = new EsriMap('map');
    const map = mapInstance.map

    map.setView([lat, long], 5);
    let marker = {};

    // If Location Exist Map It
    if ($('#latitude').val()) {
      addPinToMap($('#arcgis_address').val() || 'Lat & Lon', {
        latitude: $('#latitude').val(),
        longitude: $('#longitude').val(),
      });
    }

    // Select Location After ArcGIS Search and Populate Form
    $('#find-location-form').on('submit', function (e) {
      e.preventDefault();
      let addressOrName = $('#address_or_name').val();
      let lon = $('#lon').val();
      let lat = $('#lat').val();
      let url;
      let action;

      if (lon && lat) {
        url = `${esriProxy}/reverseGeocode?location=${lon},${lat}&Addr_type=LatLong&outFields=*&f=pjson`;
        action = 'lonAndLat';
      } else {
        url = `${esriProxy}/findAddressCandidates?SingleLine=${addressOrName}&outFields=*&f=pjson`;
        action = 'addressOrName';
      }

      $.ajax({
        url: url,
        dataType: 'json',
        success: function (data) {
          // enable form submittal again
          $('#map-it-btn').prop('disabled', false);

          if (!data['error']) {
            $('#lon').val('');
            $('#lat').val('');
            $('#address_or_name').val('');
            // map results
            if (action == 'addressOrName') {
              mapAddressOrNameResults(data);
            } else if (action == 'lonAndLat') {
              mapLonAndLatResults(data);
            }
          } else {
            alert(data['error']);
          }
        },
      });
    });


    map.on("click", function (e) {
      if ($('#locked').is(':checked')) {
        alert('To find a location with the map disable the location lock.');
        return;
      }
      L.esri.Geocoding
        .reverseGeocode({
          apikey: apiKey
        })
        .latlng(e.latlng)
        .run(function (error, result) {
          if (error) {
            return;
          }
          mapReverseGeoResults(result);
        });
    });

    function mapAddressOrNameResults(results) {
      if (results['candidates'].length > 0) {
        let firstLocationAttrs = results['candidates'][0]['attributes'];
        $('#address_or_name').val(firstLocationAttrs['PlaceName']);
        $('#arcgis_address').val(firstLocationAttrs['Place_addr']);
        $('#street_address').val(firstLocationAttrs['StAddr']);
        $('#locality').val(firstLocationAttrs['City']);
        $('#region').val(firstLocationAttrs['Region']);
        $('#region_abbr').val(firstLocationAttrs['RegionAbbr']);
        $('#postal_code').val(firstLocationAttrs['Postal']);
        $('#country').val(firstLocationAttrs['Country']);
        $('#latitude').val(firstLocationAttrs['DisplayY'])
        $('#longitude').val(firstLocationAttrs['DisplayX'])

        addPinToMap(firstLocationAttrs['Place_addr'], {
          latitude: firstLocationAttrs['DisplayY'],
          longitude: firstLocationAttrs['DisplayX'],
        });
      }
    }

    function mapLonAndLatResults(results) {
      if (results['address']) {
        let address = results['address'];
        let location = results['location'];
        $('#address_or_name').val('');
        $('#arcgis_address').val('');
        $('#street_address').val('');
        $('#locality').val(address['City']);
        $('#region').val(address['Region']);
        $('#region_abbr').val(address['RegionAbbr']);
        $('#postal_code').val(address['Postal']);
        $('#country').val(address['CountryCode']);
        $('#latitude').val(location['y']);
        $('#longitude').val(location['x']);

        addPinToMap('Lat & Lon', {
          latitude: location['y'],
          longitude: location['x'],
        });
      }
    }

    function mapReverseGeoResults(result) {
      if (result) {
        let address = result.address;
        let location = result.latlng;
        $('#address_or_name').val('');
        $('#arcgis_address').val('');
        $('#street_address').val('');
        $('#locality').val(address['City']);
        $('#region').val(address['Region']);
        $('#postal_code').val(address['Postal']);
        $('#country').val(address['CountryCode']);
        $('#latitude').val(location.lat);
        $('#longitude').val(location.lng);

        addPinToMap('Lat & Lon', {
          latitude: location.lat,
          longitude: location.lng,
        });
      }
    }

    function addPinToMap(address, pin) {
      let lat = Math.round(pin.latitude * 1000000) / 1000000;
      let lon = Math.round(pin.longitude * 1000000) / 1000000;

      if (marker != undefined) {
        map.removeLayer(marker);
      };
      marker = L.marker([lat, lon]).addTo(map)
      marker.addTo(map).bindPopup(`<b>${address}</b><div>${lat},${lon}</div>`).openPopup();
      map.setView([pin.latitude, pin.longitude], 16)
    }

    $('#clear_location').on('click', function () {
      $('#address_or_name').val('');
      $('#arcgis_address').val('');
      $('#street_address').val('');
      $('#locality').val('');
      $('#region').val('');
      $('#region_abbr').val('');
      $('#postal_code').val('');
      $('#country').val('');
      $('#longitude').val('');
      $('#latitude').val('');
    });

    $('#edit_location').on('click', function () {
      $('#location_address_form *')
        .filter(':input')
        .each(function () {
          if ($(this).prop('readonly')) {
            $(this).prop('readonly', false);
          } else {
            $(this).prop('readonly', true);
          }
        });
    });

    $('#address_or_name_checked').on('click', function () {
      $('#lon').val('');
      $('#lat').val('');
      $('#address_or_name').val('');
      $('.address_or_name_checked').prop('readonly', true);
      $('.manual_entry_checked').prop('readonly', false);
    });

    $('#manual_entry_checked').on('click', function () {
      $('#lon').val('');
      $('#lat').val('');
      $('#address_or_name').val('');
      $('.manual_entry_checked').prop('readonly', true);
      $('.address_or_name_checked').prop('readonly', false);
    });
  }
}
